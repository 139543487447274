import ExpertAdviceLP from '~/components/ExpertAdviceLP';
import type { ExpertAdviceLPProps } from '~/components/ExpertAdviceLP/ExpertAdviceLP';

import { ServerResponse } from 'http';
import { getCachedExpertAdviceFooterData, getHome } from '~/libs/contentful/api';

import { fetchNavHTML } from '~/libs/server/nav';
import { fetchUser } from '~/libs/server/users';
import { GetServerSideProps } from 'next';
import IncomingMessageWithCookies from '~/types/IncomingMessageWithCookies';

export type ExpertAdviceLPContainerProps = ExpertAdviceLPProps;

const ExpertAdviceLPContainer = (props: ExpertAdviceLPContainerProps): JSX.Element => {
  return <ExpertAdviceLP {...props} />;
};

export default ExpertAdviceLPContainer;

export const getServerSideProps: GetServerSideProps<ExpertAdviceLPContainerProps> = async ({
  req,
}: {
  req: IncomingMessageWithCookies;
  res: ServerResponse;
}) => {
  const [expertAdviceFooter, homeData, navHTML, userCtx] = await Promise.all([
    getCachedExpertAdviceFooterData(req),
    getHome(),
    fetchNavHTML(req),
    fetchUser(req),
  ]);

  const { topArticles: articles, topics, title } = homeData;

  return {
    props: JSON.parse(
      JSON.stringify({
        articles: articles.slice(0, 11),
        expertAdviceFooter,
        navHTML,
        title,
        topics,
        userCtx,
      })
    ),
  };
};
