// types
import type { PostType } from '~/libs/contentful/types';

/*
 * https://newamsterdamlabs.atlassian.net/browse/CE-2959
 *
 * To hard code an article on the ExpertAdviceLP there's only a few properties we need to provide to mock a PostType
 *  - slug
 *  - heroImage?.src
 *  - heroImage?.altText
 *  - topic?.name
 *  - title
 *  - excerpt
 *
 */
export const weddingVenueArticle = {
  topic: {
    name: 'Venues',
  },
  slug: 'best-venues',
  title: 'Top wedding venues',
  excerpt:
    'With so many venue options available, wedding planning can feel overwhelming. Hometown haunt or far-flung destination? Indoor ballroom, beach, or rustic barn? All-inclusive or DIY?',
  heroImage: {
    src: 'https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/Venues-4881907f82c2252028c973bf81083ce6.jpg',
    altText: 'Zola',
  },
} as PostType;
