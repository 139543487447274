import { UserContext } from '@zola-helpers/client/dist/es/@types';
import PreauthFooterV3 from '@zola/zola-ui/src/components/PreAuthV3/PreauthFooterV3';
import { useLocationForVendorSRPLink } from '@zola/zola-ui/src/hooks/useLocationForVendorSRPLink/useLocationForVendorSRPLink';

import { getIsGuest } from '~/libs/universal/utils/getIsGuest';
import { getShouldSkipMarketplaceLP } from '~/libs/universal/utils/marketplaceLPLinkHelper';

export const Footer = ({ userCtx }: { userCtx?: UserContext | null }) => {
  const weddingLocation = useLocationForVendorSRPLink({
    shouldCheckLocation: getShouldSkipMarketplaceLP(userCtx) || false,
  });
  return <PreauthFooterV3 isGuest={getIsGuest(userCtx)} weddingLocation={weddingLocation} />;
};

export default Footer;
