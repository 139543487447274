import React from 'react';

import { UserContext } from '@zola-helpers/client/dist/es/@types';
import Masthead from '@zola/zola-ui/src/components/PreAuthV3/Masthead';
import { BackgroundType } from '@zola/zola-ui/src/components/PreAuthV3/FeaturedImage';
import TopicTiles from '@zola/zola-ui/src/components/PreAuthV3/TopicTiles';
import * as BREAKPOINTS from '@zola/zola-ui/src/lib/ui/breakpoints';
import type {
  ExpertAdviceFooterType,
  ExpertAdviceLpTopic,
  PostType,
} from '~/libs/contentful/types';

import ExpertAdviceFooter from '~/components/expert-advice/ExpertAdviceFooter';
import { PageWrapper } from '~/components/LP/v3/PageWrapper.styles';
import Nav from '~/components/Nav';
import Header from '~/components/ExpertAdviceLP/Header';

import { desktopOnly, excludeTablet } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';

import { weddingVenueArticle } from '~/components/ExpertAdviceLP/content';
import SvgIconFromSrcUrl from '~/components/SvgIconFromSrcUrl';

// Constants
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import { useIsPostAuthNav3MobileOrDesktop } from '~/hooks/useIsPostAuthNav3Active';
import { DESKTOP_MIN } from '@zola-helpers/client/dist/es/util/responsive';

import {
  ArticleContent,
  ArticleExerpt,
  ArticleHeroImg,
  ArticleHeroImgContainer,
  ArticleTitle,
  ArticleTopic,
  Grid,
  GridItem,
} from '~/styles/expertAdviceShared.styles';

import Footer from '~/components/ui/Footer/Footer';
import mobileBackground from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/mobile_background.jpg';
import desktopBackground from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/desktop_background.jpg';
import mobileBackgroundWebp from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/mobile_background.webp';
import desktopBackgroundWebp from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/desktop_background.webp';
import foreground from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/foreground.webp';
import mobileForeground from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/mobile_foreground.webp';
import tabletForeground from '../../../public/static-preauth/images/ExpertAdviceLP/Masthead/tablet_foreground.webp';

import {
  cssFix,
  Highlight,
  Text,
  GridTextItem,
  CtaSection,
  CtaButton,
  CtaText,
} from './ExpertAdviceLP.styles';

export type ExpertAdviceLPProps = {
  expertAdviceFooter: ExpertAdviceFooterType;
  navHTML: string | null;
  userCtx: UserContext | null;
  topics: ExpertAdviceLpTopic[];
  articles: PostType[];
  title: string;
};

const ExpertAdviceLP: React.FC<ExpertAdviceLPProps> = ({
  expertAdviceFooter,
  navHTML,
  userCtx,
  topics,
  articles,
  title,
}): JSX.Element => {
  const isPostAuthNav3Enabled = useIsPostAuthNav3MobileOrDesktop(userCtx);

  const topicsContent = topics.map(({ slug, name, webIcon }, i) => {
    return {
      iconComponent: webIcon ? <SvgIconFromSrcUrl src={webIcon?.src} /> : undefined,
      name,
      href: `/expert-advice/topic/${slug}`,
      tileCssOverrides: i === 9 ? excludeTablet : undefined,
    };
  });

  const renderArticles = (): JSX.Element[] => {
    const textItem = (
      <GridTextItem key="ea-grid-tips">
        <Text>
          <Highlight>Get tips and tricks</Highlight> from our wedding experts
        </Text>
      </GridTextItem>
    );

    // Replace the last article with Top Wedding Venues
    const articlesAndTopVenues = [...articles.slice(0, -1), weddingVenueArticle];

    return [
      textItem,
      ...articlesAndTopVenues.map((a, i) => {
        return (
          <GridItem key={`${a.slug}-${i}`} css={i > 11} href={`/expert-advice/${a.slug}`}>
            <ArticleHeroImgContainer>
              <ArticleHeroImg
                src={a.heroImage?.src || ''}
                alt={a.heroImage?.altText}
                breakpointWidthMap={{ 1: 100, [DESKTOP_MIN]: 450 }}
                loading="lazy"
              />
            </ArticleHeroImgContainer>
            <ArticleContent>
              <ArticleTopic>{a.topic?.name}</ArticleTopic>
              <ArticleTitle presentation="h6">{a.title}</ArticleTitle>
              <ArticleExerpt>{a.excerpt}</ArticleExerpt>
            </ArticleContent>
          </GridItem>
        );
      }),
    ];
  };

  return (
    <>
      <Header title={title} />
      <Nav
        activePrimaryLink={PrimaryNavIdentifier.ADVICE}
        navHTML={navHTML}
        showPromo
        user={userCtx}
        disablePrimaryNavCollapse
        disableSecondary
      />
      <PageWrapper isPostAuthNav3={isPostAuthNav3Enabled}>
        <Masthead
          css={cssFix}
          btnHref="/expert-advice/topic/wedding-planning-101"
          btnText="Explore planning 101"
          buttonPropOverrides={{ primary: { textTransform: false } }}
          images={{
            background: {
              parameters: {
                alt: '',
                featuredSrc: desktopBackground,
                jpg: [
                  {
                    breakpoint: BREAKPOINTS.SCREEN_MD_MAX.toString(),
                    url: desktopBackground,
                  },
                  {
                    breakpoint: BREAKPOINTS.SCREEN_SM_MAX.toString(),
                    url: mobileBackground,
                  },
                ],
                webp: [
                  {
                    breakpoint: BREAKPOINTS.SCREEN_MD_MAX.toString(),
                    url: desktopBackgroundWebp,
                  },
                  {
                    breakpoint: BREAKPOINTS.SCREEN_SM_MAX.toString(),
                    url: mobileBackgroundWebp,
                  },
                ],
              },
              type: BackgroundType.IMAGE,
            },
            foregroundImage: {
              desktopSrc: foreground,
              imgType: 'webp',
              mobileSrc: mobileForeground,
              tabletSrc: tabletForeground,
            },
          }}
          title={
            <>
              <h1>
                Get <span> wedding advice </span> you can rely on.
              </h1>{' '}
              <h2>Our team of experts have answers to all your questions.</h2>
            </>
          }
          valueProps={[
            'Helpful articles for your whole wedding planning journey',
            'Tips, tricks, and trends on everything from budget to etiquette',
            "Want more advice? Connect directly with Team-Z and we'll answer all your questions!",
          ]}
          isPhotoOnRight
        />
        <TopicTiles title="Browse by topic" topics={topicsContent} />
        <Grid>{renderArticles()}</Grid>
        <CtaSection>
          <CtaText>
            Our Expert Advisors are always here to <br css={desktopOnly} />
            <Highlight>give advice and answer your questions</Highlight>
          </CtaText>
          <CtaButton component="a" href="/advisor" size="large" textTransform={false}>
            Meet Team-Z
          </CtaButton>
        </CtaSection>
        <ExpertAdviceFooter expertAdviceFooter={expertAdviceFooter} />
        <Footer userCtx={userCtx} />
      </PageWrapper>
    </>
  );
};

export default ExpertAdviceLP;
